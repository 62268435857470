const routes = (module.exports = require('next-routes')())

routes
  .add('home', '/', 'home2')
  // .add('companyindex', '/isveren', 'company')
  .add('employer', '/isveren', 'employer')
  .add('jobsearch', '/is-ilanlari')
  .add('jobsearchseourl', '/is-ilanlari/:seourl', 'jobsearch')
  .add('jobsearchcatchurl', '/is-ilanlari/:catchpath+', 'jobsearch')

  .add('CandidateJobSearch', '/CandidateJobSearch', 'jobsearch')
  .add('servicejobs', '/hizmetler', 'jobsearch')
  .add('servicejobsseourl', '/hizmetler/:seourl', 'jobsearch')

// .add('services', '/hizmet')
// .add('position', '/hizmet/:seourl', 'services/position')

  .add('jobdetaildeeplink', '/is-ilani/ilan-:id', 'jobdetail')
  .add('jobdetail', '/is-ilani/:seourl')
  .add('jobdetailcatchurl', '/is-ilani/:catchpath+', 'jobdetail')
  .add('jobdetaildeeplink2', '/ilan', 'jobdetail')
  .add('companydetail', '/firma-profil/:seourl', 'company/detail')
  .add(
    'companydetailindividual',
    '/bireysel-firma/:seourl',
    'company/detail'
  )
  .add('companydetaildeeplink', '/firmaProfil', 'company/detail')
  .add('settings', '/ayarlar')
  .add('myworks', '/islerim')
  .add('cities', '/illere-gore-ilanlar')
  .add('towns', '/ilcelere-gore-ilanlar')
  .add('positions', '/pozisyona-gore-ilanlar')
  .add('sectors', '/sektore-gore-ilanlar')
  .add('salary', '/maas-hesaplama')
  .add('mapview', '/haritada-goster')
  .add('cookiepolicy', '/cerez-politikasi')
  .add('candidatedatapolicy', '/sozlesmeler/aday-uyelik-aydinlatma-metni')
  .add('companydatapolicy', '/sozlesmeler/isveren-uyelik-aydinlatma-metni')
  .add('agreementcandidatelatest', '/sozlesmeler/platform-uyelik-sozlesmesi')
  .add('userapplicationpolicy', '/sozlesmeler/ilgili-kisi-basvuru-formu')
  .add('contracts', '/sozlesmeler', 'candidatedatapolicy')
  .add(
    'freezedeleteaccount',
    '/ayarlar/hesap-dondur-sil',
    'settings/freezedeleteaccount'
  )
  .add('notifications', '/bildirimler')
  .add('profile', '/profil')
  .add('sms', '/s/:guid', 'sms')
  .add('contact', '/iletisim')
  .add('/hakkimizda', 'about')
  .add('/hakkimizda/isverenlere-ozel', 'about')
  .add('/hakkimizda/is-arayanlara-ozel', 'about')
  .add('safejobsearchguide', '/guvenli-is-arama-rehberi')

// .add('about')
//.add('blog', '/blog/:slug')
// .add('user', '/user/:id', 'profile')
// .add('/:noname/:lang(en|es)/:wow+', 'complex')
